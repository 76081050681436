<template>
	<w-layout fill-height row>
		<w-flex offset-xs1 xs4 mr-2>
			<w-layout align-center column fill-height justify-center>
				<w-flex v-t="'projects.no_services.title'" display-1 mb-4 shrink />
				<w-flex v-t="'projects.no_services.first_sentence'" mb-2 shrink subheading />
				<w-flex v-t="'projects.no_services.second_sentence'" mb-2 shrink subheading />
				<w-btn v-if="isAccountantAdmin" @click.stop="addServices()">{{ $t('projects.add_services') }}</w-btn>
			</w-layout>
		</w-flex>
		<w-flex xs6 ml-2>
			<w-layout align-center fill-height>
				<UndefinedCatalogImage height="80%" />
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'NoExistingProjects',
	components: {
		UndefinedCatalogImage: () => ({
			component: import('@/components/Offers/CustomersManager/UndefinedCatalogImage')
		})
	},
	mixins: [ProjectsManagerModuleGuard],
	computed: {
		...mapState({
			isAccountantAdmin: state => state.accountingFirm.selected.isAccountantAdmin
		})
	},
	methods: {
		addServices: function () {
			this.appService.goTo('customer-services-wizard')
		}
	}
}
</script>
